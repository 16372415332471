<template>
    <section v-loading="loading" class="sec-recibos-pagos">
        <cargando v-if="cargando" />
        <div class="row justify-content-between">
            <div class="col-auto">
                <p class="f-20 f-500">Recibos de pago</p>
            </div>
            <div class="col-auto">
                <button class="d-flex align-items-center btn-general btn btn-sm f-14 h-36px wm-152px" @click="abrirModalConfigurarPagos">
                    <i class="icon-config f-20 mr-1" />
                    Configurar pago
                </button>
            </div>
        </div>
        <div class="row">
            <p class="col-12 f-15 mb-4"> Configura y envía los recibos de pago de administración a todos los residentes de manera fácil y sencilla. </p>
            <div class="col-auto pr-2">
                <el-select v-model="selectYears" placeholder="Seleccionar año" size="small" @change="listarDatosViviendas">
                    <el-option v-for="(item,key) in yearArray" :key="key" :label="item.anio" :value="item.anio">
                        <p class="ucfirst">{{ item.anio }}</p>
                    </el-option>
                </el-select>
            </div>
            <div class="col-auto px-2">
                <el-select v-model="selectMonths" placeholder="Seleccionar mes" size="small" @change="listarDatosViviendas">
                    <el-option v-for="(item,index) in monthsArray" :key="index" :label="item.name" :value="item.id">
                        <p class="ucfirst">{{ item.name }}</p>
                    </el-option>
                </el-select>
            </div>
            <div class="col-auto px-2">
                <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="editar-residente br-4">
                    <template #content>
                        <template v-if="filtro.length">
                            <div class="d-flex flex-column gap-2 w-177px">
                                <p class="d-flex justify-content-end f-11 text-blue cr-pointer" @click="limpiarFiltro">Borrar filtro</p>
                                <div v-for="item, index in filtro" :key="index">
                                    <p class="f-medium text-86 f-12 border-bottom border-light mb-1">{{ item.titulo }}</p>
                                    <p class="f-light text-86 f-12">{{ item.nombre }}</p>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            Filtrar
                        </template>
                    </template>
                    <button-filter
                    :filtering="filtro.length > 0"
                    class="h-32px"
                    @click="abrirModalFiltroPorVivienda"
                    />
                </el-tooltip>
            </div>
            <div class="col-auto px-2 ml-auto">
                <export-excel
                :data="examplePlantilla"
                :fields="excel.campos"
                :before-generate="() => excel.loading = true"
                :before-finish="() => excel.loading = false"
                worksheet="pagos"
                name="administracion.xls"
                >
                    <button v-loading="excel.loading" class="border f-14 text-86 br-4 h-32px wm-170px d-middle-center bg-whitesmoke">
                        <i class="icon-download f-20" />
                        <span>Descargar plantilla</span>
                    </button>
                </export-excel>
            </div>
            <div class="col-auto px-2">
                <button :disabled="disabledPlantilla" class="border f-14 text-86 br-4 h-32px wm-170px d-middle-center bg-whitesmoke" @click="modalImportar">
                    Subir plantilla
                </button>
            </div>
            <div class="col-auto px-2">
                <button :disabled="disabledPlantilla" class="border f-14 br-4 h-32px wm-170px d-middle-center btn-general" @click="abrirModalEnviarReciboPagos">
                    Enviar recibo
                </button>
            </div>
        </div>
        <div class="mt-4">
            <el-table :data="viviendaConceptos.viviendaConceptos" :height="viviendaConceptos.viviendaConceptos.length < 10 ? '400' : 'calc(100vh - 310px)' " class="pruebaaaaa" header-cell-class-name="bg-general text-white" header-row-class-name="rounded-circle">
                <el-table-column fixed label="Vivienda" sortable min-width="180">
                    <template #default="scope">
                        <p class="text-general f-16 f-400">{{ scope.row.vivienda_nombre }}</p>
                    </template>
                </el-table-column>
                <el-table-column v-for="(item, index) in viviendaConceptos.conceptos"
                                 :key="index"
                                 :prop="item.valor"
                                 :fixed="item.fixed || false"
                                 :label="item.nombre"
                                 :min-width="item.width || 200"
                                 :align="item.align"
                >
                    <template slot="header">
                        <div>
                            <p>{{ item.codigo }}</p>
                            <p class="ucfirst">{{ item.nombre }}</p>
                        </div>
                    </template>
                    <template slot-scope="scope">
                        <div v-for="item2 in scope.row.conceptos" :key="item2.id">
                            <template v-if="item.id === item2.id_concepto">
                                <p v-if="!scope.row.editing">{{ convertMoney(item2.valor) }}</p>
                                <input v-else v-model="item2.valor" class="border br-4 h-32px px-2" type="text" />
                            </template>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="Total a pagar" sortable min-width="135">
                    <template #default="scope">
                        <p class="text-blue">{{ convertMoney(scope.row.valorTotal) }}</p>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="" min-width="150">
                    <template #default="scope">
                        <div class="d-middle-center gap-2">
                            <el-tooltip content="Ver recibo" effect="light" :visible-arrow="false" popper-class="border-0 shadow-sm rounded-pill">
                                <div v-if="!scope.row.editing" class="d-middle-center wh-32 btn-action cr-pointer" @click="verRecibo(scope.row)">
                                    <i class="icon-eye f-12 position-relative" style="left: -4px;" />
                                </div>
                            </el-tooltip>
                            <el-tooltip v-if="!disabledPlantilla" content="Editar recibo" effect="light" :visible-arrow="false" popper-class="border-0 shadow-sm rounded-pill">
                                <div v-if="!scope.row.editing" class="d-middle-center wh-32 btn-action cr-pointer" @click="editarRecibo(scope.row, 'editando')">
                                    <i class="icon-pencil-outline" />
                                </div>
                                <div class="d-middle-center wh-32 btn-action cr-pointer" @click="editarRecibo(scope.row,'guardando')">
                                    <i class="icon-ok-circled-outline text-success f-18" />
                                </div>
                            </el-tooltip>
                            <el-tooltip v-if="!disabledPlantilla" content="Enviar recibo" effect="light" :visible-arrow="false" popper-class="border-0 shadow-sm rounded-pill">
                                <div v-if="!scope.row.editing" class="d-middle-center wh-32 btn-action cr-pointer" @click="enviarRecibo(scope.row)">
                                    <i class="icon-factura f-21" />
                                </div>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- <modal-filtro-vivienda ref="refModalFiltroVivienda" /> -->
        <modal-filtro-vivienda
        ref="refModalFiltroVivienda"
        :agrupaciones="agrupaciones"
        :viviendas="viviendas"
        mostrar-filtro-vivienda
        @agrupacionSeleccionada="obtenerViviendasPorIdAgrupacion"
        @filtrar="aplicarFiltro"
        @limpiar="getViviendaConceptos(null)"
        />
        <modal ref="refModalEnviarReciboPago" titulo="Enviar recibo de pago" guardar tamano="modal-md" adicional="Si, enviar" @adicional="enviarReciboPagoGrupo">
            <p class="f-15 text-center wM-345px my-3 mx-auto">¿Deseas enviar el recibo de pago de la administración a todas las viviendas?</p>
        </modal>
        <modal ref="refModalEnviarReciboPagoIndividual" titulo="Enviar recibo de pago" guardar tamano="modal-md" adicional="Si, enviar" @adicional="enviarReciboPagoIndividual">
            <p class="f-15 text-center wM-345px my-3 mx-auto">¿Deseas enviar el recibo de pago a esta vivienda?</p>
        </modal>
        <modal ref="modalImportarPlantilla" titulo="Importar plantilla" no-aceptar adicional="Cargar" @adicional="cargarExcel">
            <div class="row mx-3 my-4">
                <el-upload
                ref="upload"
                action="#"
                :on-change="handlePreview"
                :on-remove="handleRemove"
                :on-exceed="handleExceed"
                :auto-upload="false"
                :multiple="false"
                :limit="1"
                accept=".xlsx, .xls"
                >
                    <el-button class="bg-general text-white br-6">
                        <i class="icon-arrow-up el-icon--right f-15" />Seleccionar archivo
                    </el-button>
                    <div slot="tip" class="el-upload__tip f-15">
                        Solo se aceptan archivos Excel, extención (.xlsx)
                    </div>
                </el-upload>
            </div>
        </modal>
        <modal-configurar-pagos ref="refModalConfigurarPagos" @actualizar="actualizarRecivoPago" />
    </section>
</template>

<script>
import Vue from 'vue'
import Administracion from '~/services/pago_administracion'
import Viviendas from '~/services/viviendas'
import moment from 'moment'
import excel from 'vue-excel-export'

Vue.use(excel)
moment.locale('es');

import ModalConfigurarPagos from './partials/modalConfigurarPagos.vue';
import modalFiltroVivienda from '../visitantes/partials/modalFiltroVisitante.vue';
import inputEdit from './components/inputEdit.vue'

export default {
    name: 'ReciboPagos',
    components: {
        modalFiltroVivienda,
        ModalConfigurarPagos
    },
    data(){
        return {
            cargando : false,
            loading: false,
            disabledPlantilla: true,
            filtro: [],
            agrupaciones: [],
            viviendas: [],
            selectYears: '',
            selectMonths: '',
            yearArray: [],
            monthsArray: [],
            currentMonth: '',
            viviendaConceptos: {
                conceptos: [],
                viviendaConceptos: [],
            },
            examplePlantilla:[],
            excel: {
                loading: false,
                campos: {
                    'torre': 'torre',
                    'Vivienda': 'vivienda'
                },
            },
            yearsActual: '',
            monthsActual: '',
            file:null,
            enviarFacturas: []
        }
    },
    computed: {
    },
    mounted(){
        this.getViviendaConceptos();
        this.getSelectAnio();
        this.setMonths();
        this.monthsActual = moment().month()+1;
        this.yearsActual = moment().year();
    },
    methods: {
        setMonths(){
            this.monthsArray = Array.from({ length: 12 }, (_, monthIndex) => {
                let monthMoment = moment().month(monthIndex);
                return {
                    id: monthIndex +1,
                    name: monthMoment.format('MMMM'),
                };
            });
        },
        abrirModalConfigurarPagos(){
            this.$refs.refModalConfigurarPagos.toggle();
        },
        async abrirModalFiltroPorVivienda(){
            await this.obtenerAgrupaciones();
            this.$refs.refModalFiltroVivienda.toggle();
        },
        async abrirModalEnviarReciboPagos(){
            this.$refs.refModalEnviarReciboPago.toggle();
            this.enviarFacturas = this.viviendaConceptos.viviendaConceptos;
        },
        enviarReciboPagoGrupo(){
            this.enviarReciboPago(1);
        },
        async enviarReciboPago(tipo){
            try {
                this.cargando = true;

                const payload = {
                    'viviendaPagos' : this.enviarFacturas,
                    anio: this.selectYears,
                    mes: this.selectMonths
                };

                const { data } = await Administracion.enviarReciboPagos(payload);

                if (tipo == 1) this.$refs.refModalEnviarReciboPago.toggle();
                if (tipo == 2) this.$refs.refModalEnviarReciboPagoIndividual.toggle();

                this.enviarFacturas = [];
                this.notificacion('','Enviado correctamente','success');
            } catch (error){
                return this.errorCatch(error)
            } finally{
                this.cargando = false;
            }
        },
        enviarRecibo(item){
            this.enviarFacturas = [];
            this.enviarFacturas.push(item);
            this.$refs.refModalEnviarReciboPagoIndividual.toggle();
        },
        enviarReciboPagoIndividual(){
            this.enviarReciboPago(2);
        },
        async verRecibo(info){
            try {
                if (info.valorTotal > 0){
                    const params = {
                        anio: this.selectYears,
                        mes: this.selectMonths
                    }

                    const {data} = await Administracion.generarPdfPagos(info.id, params);
                    const url = window.URL.createObjectURL(new Blob([data], {type:"application/pdf"}));
                    const pdfWindow = window.open(url);
                    pdfWindow.print();
                }
            } catch (error){
                return this.notificacion('Error','Aún no se genera el envio del soporte a la vivienda','error');
            }
        },
        async obtenerAgrupaciones(){
            try {
                const { data } = await Viviendas.getViviendas()
                this.agrupaciones = data.data;
            } catch (error){
                return this.errorCatch(error)
            }
        },
        async obtenerViviendasPorIdAgrupacion(id_agrupacion = null){
            try {
                const params = { id_padre: id_agrupacion }
                const { data } = await Viviendas.getViviendasHijas(params);
                if(data.success) this.viviendas = data.data;
            } catch (error){
                return this.errorCatch(error)
            }
        },
        async aplicarFiltro(filtrado){
            this.filtro = filtrado.datos;
            await this.getViviendaConceptos();
            this.$refs.refModalFiltroVivienda.toggle();
        },
        async editarRecibo(dato, tipo){
            if (tipo === 'guardando'){
                try {
                    const params = {
                        conceptos: dato.conceptos,
                        anio: this.selectYears,
                        mes: this.selectMonths
                    }
                    const { data } = await Administracion.putViviendaConcepto(params);
                    if (data.success){
                        dato.valorTotal = dato.conceptos.reduce((acumulador, objeto) => acumulador + parseInt(objeto.valor), 0);
                        dato.conceptos = data.data;
                        this.notificacion('','Actualizado correctamente','success');
                    }
                } catch (error){
                    this.errorCatch(error);
                    dato.editing = !dato.editing;
                    return this.getViviendaConceptos();
                }
            }

            dato.editing = !dato.editing
        },
        async getSelectAnio(){
            try {
                const { data } = await Administracion.getReciboPagosAnio();

                if (data.success){
                    this.yearArray = data.data;
                }
            } catch (error){
                return this.errorCatch(error)
            }
        },
        async getViviendaConceptos(){
            try {
                this.cargando = true;

                const params = {
                    idAgrupacion: this.$refs.refModalFiltroVivienda.agrupacion_id,
                    idVivienda: this.$refs.refModalFiltroVivienda.vivienda_id,
                    anio: this.selectYears,
                    mes: this.selectMonths
                }

                const { data } = await Administracion.listarViviendaConceptos(params);
                if (data.success){
                    this.viviendaConceptos = data.data;
                    if (this.examplePlantilla.length === 0) this.exportarPlantilla();
                };

            } catch (error){
                return this.errorCatch(error)
            } finally{
                this.cargando = false;
            }
        },
        listarDatosViviendas(){
            if (this.selectYears != '' && this.selectMonths != ''){
                this.getViviendaConceptos();

                if (this.yearsActual == this.selectYears && this.monthsActual == this.selectMonths){
                    this.disabledPlantilla = false;
                }else{
                    this.disabledPlantilla = true;
                }
            }
        },
        limpiarFiltro(){
            this.filtro = []
            this.$refs.refModalFiltroVivienda.limpiarFiltro()
        },
        async exportarPlantilla(){
            const nombreConceptos = this.viviendaConceptos.conceptos.map(objeto => objeto.nombre);
            nombreConceptos.forEach((item, key) => {
                this.excel.campos[item] = '0';
            });

            this.examplePlantilla = this.viviendaConceptos.viviendaConceptos;
        },
        modalImportar(){
            this.$refs.upload.clearFiles();
            this.$refs.modalImportarPlantilla.toggle();
        },
        handleRemove(file, fileList){
            this.file = null;
        },
        handlePreview(file){
            this.file = file.raw;
        },
        handleExceed(files, fileList){
            this.$message.warning(`Solo se puede seleccionar 1 archivo`);
        },
        async cargarExcel(){
            try {
                if (this.file == null) return;
                this.cargando = true;

                let formData = new FormData();
                formData.append("archivo", this.file);
                formData.append("anio", this.selectYears);
                formData.append("mes", this.selectMonths);

                const {data} = await Administracion.importarExcelEstructura(formData);

                this.$refs.modalImportarPlantilla.toggle();
                this.getViviendaConceptos();
            }catch(e){
                this.error_catch(e)
            }  finally{
                this.cargando = false;
            }
        },
        async actualizarRecivoPago(){
            await this.limpiarFiltro();
            this.getViviendaConceptos();
        }
    },
}
</script>

<style lang="scss" scoped>
.sec-recibos-pagos{
    &::-webkit-scrollbar-track{ height: 6px; width:0px; -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.0); background-color:transparent; border-radius: 5px; }
    &::-webkit-scrollbar{ background-color:transparent; width:0px; height: 6px; border-radius: 5px; }
    &::-webkit-scrollbar-thumb{ -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.0); background-color:transparent; border-radius: 5px; }
}
</style>